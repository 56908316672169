<template>
  <div class="container">
    <van-sticky>
      <van-nav-bar title="我的心声" left-arrow @click-left="$router.back()" />
    </van-sticky>

    <van-list
      v-model="upLoading"
      :finished="finished"
      @load="onLoad"
      finished-text="没有更多了"
    >
      <van-cell-group>
        <!-- art_id有可能是大数字 超过一定长度会转成 BigNumber  要toString一下 -->

<van-swipe-cell  
          v-for="item in articles"
          :key="item.id.toString()">

        <van-cell>
         
          <van-col span="24" @click="toDetail(item.id.toString())">
           
              <h3 style="display: inline-block">{{ item.title }}</h3>
              <van-tag v-if="item.systemType==1"
          round
          color="#9d1d22"
          >S7攻略</van-tag
        >
         <van-tag v-if="item.systemType==2"
          round
          color="#9d1d22"
          >优秀推荐</van-tag
        >

               <div style="float: right; color: #696969">
              {{ item.creationTime | relTime }}
            </div>
              </van-col>
        </van-cell>
        <template #right>
              <van-button
                square
                text="删除"
                type="danger"
                class="delete-button"
                @click="DeleteBindHandle(item)"
              />
              
            </template>
          </van-swipe-cell>
      </van-cell-group>
    </van-list>
  </div>
</template>

<script>
import { Toast } from "vant";
import { getMyBbsList,deleteBbs } from "@/api/bbs";

//const key = "zn-history";
export default {
  name: "zncourselist",
  data() {
    return {

      keyword: "",
      upLoading: false,
      finished: false,
      articles: [],
      pagePara: {
        current: 1,
        pageSize: 10,
        filterData: {
            
        },
      },
    };
  },
  components: {},
  created() {
    this.initData();
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.query != from.query) {
        this.initData();
        this.onSearch();
      }
    },
  },
  mounted() {},

  methods: {
    async initData() {
      if(this.$route.query.id!=undefined)
      {
     this.pagePara.filterData.schoolClass= this.$route.query.id;
      this.pagePara.filterData.schoolClass2 =0;
      }
    },
   

    onSearch() {
      this.articles = [];
      this.pagePara.current = 1;
      this.pagePara.pageSize = 10;
      this.onLoad();
    },
    toDetail(id) {
      this.$router.push({
        path: "/bbs/detail",
        query: { para: id,back:true },
      });
    },
     async DeleteBindHandle(item) {
      await this.$dialog.confirm({
        title: "提示",
        message: "确定要删除"+item.title+"吗",
      });

      const data = await deleteBbs(item);
      if (data.data.success) {
        Toast("删除成功");
        //this.$router.back();
        this.onSearch();
      } else {
        Toast(data.data.errorMessage);
      }
    },

    async onLoad() {

this.upLoading = true;
      let aresult = await getMyBbsList({ ...this.pagePara });

      this.articles.push(...aresult.data.data);
      this.upLoading = false;
      if (this.pagePara.current * this.pagePara.pageSize < aresult.data.total) {
        this.pagePara.current++;
         this.finished = false;
      } else {
        this.finished = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.w100 {
  /deep/ img {
    width: 100%;
    max-height: 200px;
  }
}
.zntitle {
  height: 80px;
}
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}
.detail {
  padding: 46px 10px 44px;
  .title {
    font-size: 18px;
    line-height: 2;
  }
  .zan {
    text-align: center;
    padding: 10px 0;
    .active {
      border-color: red;
      color: red;
    }
  }
  .author {
    padding: 10px 0;
    display: flex;
    position: sticky;
    background-color: #fff;
    top: 46px;
    z-index: 2;
    .text {
      flex: 1;
      padding-left: 10px;
      line-height: 1.5;
      .name {
        font-size: 14px;
        margin: 0;
      }
      .time {
        margin: 0;
        font-size: 12px;
        color: #999;
      }
    }
  }
  .content {
    padding: 20px 0;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-all;
    /deep/ img {
      max-width: 100%;
      background: #f9f9f9;
    }
    /deep/ code {
      white-space: pre-wrap;
    }
  }
}
</style>
